export const region = "ap-south-1";
export const mandatorySignIn = true;
export const scope = ["email", "openid", "profile"];
export const responseType = "code";

export const localhost = "localhost";
export const devAppLink = "dev-arena.interviewdesk.in";
export const preProdAppLink = "pp-arena.interviewdesk.in";
export const prodAppLink = "arena.interviewdesk.in";

export const dev = "development";
export const preprod = "preprod";
export const prod = "prod";

export const redirectBaseUrls = {
  [dev]:
    "https://idsk-coreinfracdkdevwave-dev-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=26fd34l8aje5aidcsqrq81qds2&response_type=code&scope=email+openid+profile&redirect_uri=",
  [preprod]:
    "https://idsk-coreinfracdkpreprodwave-preprod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=3k926e6iqkba1lf67gdj83uen1&response_type=code&scope=email+openid+profile&redirect_uri=",
  [prod]:
    "https://idsk-coreinfracdkprodwave-prod-candidate.auth.ap-south-1.amazoncognito.com/login?client_id=60h41369mpsh1a6rkj7mh5s91v&response_type=code&scope=email+openid+profile&redirect_uri=",
};

export const unAuthCognitoIdentityPoolIds = {
  [dev]: "ap-south-1:4ef89643-4255-4d90-9b4d-c5c30704e838",
  [preprod]: "ap-south-1:75ac10ea-dcc6-4612-8ca2-23016241654b",
  [prod]: "ap-south-1:af2eac06-d4b5-48ae-8e6e-beecefa80275",
};
